const KeysWords = [
  " Veterinary docter online",
  " Veterinary docter cow / rabbit / new me",
  " Veterinary docter salary",
  " Veterinary docter fees",
  " Veterinary docter course",
  " Veterinary docter jods",
  " Veterinary docter kese bane",
  " Goat farming training",
];
export default KeysWords;